import { graphql, useStaticQuery } from "gatsby"

import ContactDemo from "../components/contactDemo"
import Img from "gatsby-image"
// Import Components
import Layout from "../components/layout"
import { Link } from "gatsby"
import React from "react"
// Video Player
import ReactPlayer from "react-player"
import SEO from "../components/seo"

const Pay = () => {
  const data = useStaticQuery(graphql`
    query {
      rooam_pay_hero: file(relativePath: { eq: "phones/Rooam-Pay-Hero.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      iPhoneHardware: file(relativePath: { eq: "phones/iPhone-hardware.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooamPay: file(
        relativePath: { eq: "phones/Rooam-Pay-Android-Check-Full.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooam_pay_split: file(
        relativePath: { eq: "phones/Rooam-Pay-Split.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooamPayFind: file(
        relativePath: { eq: "phones/Rooam-Pay-Android-Find.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Rooam Pay - A secure mobile web experience that requires no app download"
        description="Guests seamlessly make transactions with mobile wallets, while operators leverage industry leading fraud tools."
      />
      <div className="top-message text-center background-dark-grey">
        <p className="no-top-bottom small font-weight-400 text-light-grey">
          Want a 100% walk-out proof solution? View{" "}
          <Link to="/tab/" className="text-link-white font-weight-600">
            Rooam Tab
          </Link>
        </p>
      </div>
      <div className="section no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Rooam Pay</h1>
          <p className="lead text-light-grey no-bottom">
            A secure mobile web experience that requires no app download. Guests
            seamlessly make transactions with mobile wallets, while operators
            leverage industry leading fraud tools.
          </p>
        </div>
      </div>

      <div className="section">
        <div className="container float-middle" style={{ maxWidth: 600 }}>
          <Img
            fluid={data.rooam_pay_hero.childImageSharp.fluid}
            alt="Rooam Pay inside an iPhone"
            loading="eager"
          />
        </div>
      </div>

      <div className="section steps no-top">
        <div className="container">
          <h3 className="more text-white no-top">How it works</h3>
        </div>
        <div className="container columns mobile-columns">
          <div className="row clearfix">
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle blue no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      1
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Receive
                </p>
                <p className="small text-light-grey no-bottom">
                  Server drops off printed receipt or sends guest a text message
                  with link.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle purple no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      2
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">Open</p>
                <p className="small text-light-grey no-bottom">
                  Guest then scans QR code or opens link to view their check in
                  a mobile browser.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle pink no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      3
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">View</p>
                <p className="small text-light-grey no-bottom">
                  Guest reviews their check in full, selects tip, and checks
                  out.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle orange no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      4
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">Pay</p>
                <p className="small text-light-grey no-bottom">
                  Guest completes payment. The location gets paid directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container">
          <div className="player-wrapper video-rounded video-border no-select">
            <ReactPlayer
              url="https://media.rooam.co/video/b2b/Rooam-ViewCheck-Dynamic.mp4"
              className="react-player video-rounded"
              playing
              width="100%"
              height="100%"
              muted={true}
              controls={true}
              light="../videos/posters/Rooam-Pay.jpg"
              playIcon={
                <div className="play-btn dark">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-icon"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 22v-20l18 10-18 10z" />
                    </svg>
                  </span>
                </div>
              }
            />
          </div>
        </div>
        <div className="container">
          <p className="small text-grey text-center">
            Watch an overview of how Rooam Pay works
          </p>
        </div>
      </div>

      <div className="section no-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <div className="phone-hardware">
                  <div className="hardware-screen background-dark">
                    <div className="player-wrapper tall">
                      <ReactPlayer
                        url={[
                          {
                            src: "../videos/Rooam-Pay-Flow.mp4",
                            type: "video/mp4",
                          },
                          {
                            src: "../videos/Rooam-Pay-Flow.webm",
                            type: "video/webm",
                          },
                        ]}
                        className="react-player"
                        playing
                        width="100%"
                        height="100%"
                        loop={true}
                        playsinline={true}
                        muted={true}
                      />
                    </div>
                  </div>
                  <Img
                    fluid={data.iPhoneHardware.childImageSharp.fluid}
                    alt="iPhone hardware shell to host the animating video"
                    loading="eager"
                  />
                </div>
              </div>
            </div>
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">Rooam Pay</span>{" "}
                  &mdash; The perfect balance of guest convenience and payment
                  security.
                </h2>
                <ul className="extras no-bottom">
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Accept Apple Pay &amp; Google Pay.
                      </span>{" "}
                      With no extra hardware or fees.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Advanced Fraud Protection.
                      </span>{" "}
                      Strengthen security by flagging cards that are high risk,
                      before the transaction.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Multiuse QR Code.
                      </span>{" "}
                      Use the same QR Code for touchless menus, mobile ordering,
                      and payments.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Increase Guest Engagement.
                      </span>{" "}
                      Capture guest emails during checkout.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Higher Tips For Staff.
                      </span>{" "}
                      Partner data has shown tip increases of 33%.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full sticky">
              <div
                className="padding more no-top float-middle"
                style={{ maxWidth: 400 }}
              >
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">
                    Let Your Guests Split The Check
                  </span>{" "}
                  &mdash; Never have your servers run multiple credit cards
                  again.
                </h2>
                <ul className="extras">
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        No One Likes Math.
                      </span>{" "}
                      Guests can easily split their check by number of people or
                      enter a specific dollar amount.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <Img
                  fluid={data.rooam_pay_split.childImageSharp.fluid}
                  alt="Rooam Pay Split The Check Screen"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section more">
        <div className="container text-center">
          <h1 className="text-white no-top">
            &ldquo;So easy, normally browser based payments on a phone can be
            annoying, but this was great.&rdquo;
          </h1>
          <p className="text-grey no-bottom">
            <span className="font-weight-600">Brian C.</span> &mdash; Rooam Pay
            User
          </p>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Request a demo</h1>
        </div>
      </div>

      <div className="section no-top">
        <ContactDemo formName="request-demo-pay" />
      </div>
    </Layout>
  )
}

export default Pay
